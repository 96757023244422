import { Controller } from "@hotwired/stimulus";
import { GridStack } from "gridstack";

// Connects to data-controller="grid-stack"
export default class extends Controller {
  // static targets = ["form"];
  static values = {
    userId: Number,
    dashboardName: String,
    dashboardDataUrl: String,
    dashboardData: {type: Array, default: []},
    nameParam: {type: String, default: "name"},
    dataParam: {type: String, default: "data"}
  };

  connect() {
    this._createContainer();
    this._gridInit();
  }

  save() {
    const items = this._grid.save(false, true);
    console.log(items);
  }

  disconnect() {
    this._grid.destroy();
  }

  _createContainer() {
    if (this._container) return;
    const container = this.element.querySelector(".grid-stack");
    if (container) {
      this._container = container;
    } else if (this.element.classList.contains("grid-stack")) {
      this._container = this.element;
    } else {
      const container = document.createElement("div");
      container.classList.add("grid-stack");
      this._container = container;
      this.element.appendChild(this._container);
    }
  }

  _gridInit() {
    if (this._grid) return;

    this._data = this.dashboardDataValue;
    this._grid = GridStack.init({
      float: false,
      column: 6,
      cellHeight: "10rem",
      margin: "0.5rem",
      handle: this.hasItemHandle ? ".grid-stack-item-handle" : null,
      children: this._data
    }, this._container);

    this._grid.on("change", (event, items) => {
      console.log("change", event);
      this._saveData();

      // Resize on change
      items.forEach(item => this._resizeHandle(item.el));
    });

    // Resize on init
    this.element.querySelectorAll(".grid-stack-item").forEach(itemEl => this._resizeHandle(itemEl));
  }

  _resizeHandle(itemEl) {
    const itemContentEl = itemEl.querySelector(".grid-stack-item-content");
    const itemContentWidth = itemContentEl.clientWidth;
    const itemContentHeight = itemContentEl.clientHeight;
    const itemTitleEl = itemContentEl.querySelector(".grid-stack-item-title");
    const itemBodyEl = itemContentEl.querySelector(".grid-stack-item-body");
    let itemBodyHeight = itemContentHeight;
    if (itemTitleEl) {
      itemTitleEl.style.width = `${itemContentWidth}px`;
      const cardTitleHeight = itemTitleEl.clientHeight;
      itemBodyHeight = itemContentHeight - cardTitleHeight;
    }
    if (itemBodyEl) {
      itemBodyEl.style.width = `${itemContentWidth}px`;
      itemBodyEl.style.height = `${itemBodyHeight}px`;
    }
  }

  _saveData() {
    if (this._data === this.gridState) return;

    this._data = this.gridState;
    Rails.ajax({
      url: this.dashboardDataUrlValue,
      type: "POST",
      data: `dashboard[name]=${this.dashboardNameValue}&dashboard[data]=${JSON.stringify(this._data)}`,
      success: (response) => {
        console.log("Save-Success:", response);
      },
      error: (error) => {
        console.error("Save-Error:", error);
      }
    });
  }

  get hasItemHandle() {
    return this.element.querySelector(".grid-stack-item-handle") !== null;
  }

  get gridState() {
    return this._grid.save(false);
  }
}