import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "../../vendor/mosaic_css/js/mdb.pro";

// Connects to data-controller="mdb--tooltip"
export default class extends Controller {

  connect() {
    new Tooltip(this.element);
  }

  disconnect() {
    Tooltip.getInstance(this.element).dispose();
  }
}
