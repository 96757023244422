import Swal from "sweetalert2";

export const SwalConfirm = Swal.mixin({
  position: "center-center",
  showCancelButton: true,
  showCloseButton: false,
  showDenyButton: false,
  confirmButtonText: "Confirmar",
  denyButtonText: "Negar",
  cancelButtonText: "Cancelar",
  allowOutsideClick: false
});